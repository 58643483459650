/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateClientInput = {
  id?: string | null,
  legalName: string,
};

export type ModelClientConditionInput = {
  legalName?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  legalName: string,
  devices?: ModelDeviceConnection | null,
  users?: ModelUserConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items:  Array<Device | null >,
  nextToken?: string | null,
};

export type Device = {
  __typename: "Device",
  clientID: string,
  name: string,
  location?: string | null,
  description?: string | null,
  lastEvent?: Event | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Event = {
  __typename: "Event",
  clientID: string,
  createdAt: string,
  type: EventTypeCode,
  device?: Device | null,
  deviceName: string,
  user?: User | null,
  userName: string,
  updatedAt: string,
};

export enum EventTypeCode {
  applicationStart = "applicationStart",
  applicationStop = "applicationStop",
  sessionStart = "sessionStart",
  sessionStop = "sessionStop",
  sessionLock = "sessionLock",
  sessionUnlock = "sessionUnlock",
  screenSaverStart = "screenSaverStart",
  screenSaverStop = "screenSaverStop",
}


export type User = {
  __typename: "User",
  clientID: string,
  name: string,
  description?: string | null,
  lastEvent?: Event | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type UpdateClientInput = {
  id: string,
  legalName?: string | null,
};

export type DeleteClientInput = {
  id: string,
};

export type CreateDeviceInput = {
  clientID: string,
  name: string,
  location?: string | null,
  description?: string | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
};

export type ModelDeviceConditionInput = {
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  lastEventId?: ModelIDInput | null,
  lastEventCreatedAt?: ModelStringInput | null,
  and?: Array< ModelDeviceConditionInput | null > | null,
  or?: Array< ModelDeviceConditionInput | null > | null,
  not?: ModelDeviceConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateDeviceInput = {
  clientID: string,
  name: string,
  location?: string | null,
  description?: string | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
};

export type DeleteDeviceInput = {
  clientID: string,
  name: string,
};

export type CreateUserInput = {
  clientID: string,
  name: string,
  description?: string | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
};

export type ModelUserConditionInput = {
  description?: ModelStringInput | null,
  lastEventId?: ModelIDInput | null,
  lastEventCreatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  clientID: string,
  name: string,
  description?: string | null,
  lastEventId?: string | null,
  lastEventCreatedAt?: string | null,
};

export type DeleteUserInput = {
  clientID: string,
  name: string,
};

export type CreateEventInput = {
  clientID: string,
  createdAt?: string | null,
  type: EventTypeCode,
  deviceName: string,
  userName: string,
};

export type ModelEventConditionInput = {
  type?: ModelEventTypeCodeInput | null,
  deviceName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelEventTypeCodeInput = {
  eq?: EventTypeCode | null,
  ne?: EventTypeCode | null,
};

export type UpdateEventInput = {
  clientID: string,
  createdAt: string,
  type?: EventTypeCode | null,
  deviceName?: string | null,
  userName?: string | null,
};

export type DeleteEventInput = {
  clientID: string,
  createdAt: string,
};

export type CreatePopupInput = {
  clientID: string,
  createdAt?: string | null,
  type: PopupTypeCode,
  resultType: PopupResultTypeCode,
  answeredCorrectly: boolean,
  mouseXY?: string | null,
  buttonClicked?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  deviceName: string,
  userName: string,
};

export enum PopupTypeCode {
  presenceCheck = "presenceCheck",
  question = "question",
}


export enum PopupResultTypeCode {
  answerGood = "answerGood",
  answerWrong = "answerWrong",
  timeOut = "timeOut",
  notShown_SessionLocked = "notShown_SessionLocked",
  notShown_VideoInUse = "notShown_VideoInUse",
  notShown_NotActivePeriod = "notShown_NotActivePeriod",
}


export type ModelPopupConditionInput = {
  type?: ModelPopupTypeCodeInput | null,
  resultType?: ModelPopupResultTypeCodeInput | null,
  answeredCorrectly?: ModelBooleanInput | null,
  mouseXY?: ModelStringInput | null,
  buttonClicked?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  deviceName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  and?: Array< ModelPopupConditionInput | null > | null,
  or?: Array< ModelPopupConditionInput | null > | null,
  not?: ModelPopupConditionInput | null,
};

export type ModelPopupTypeCodeInput = {
  eq?: PopupTypeCode | null,
  ne?: PopupTypeCode | null,
};

export type ModelPopupResultTypeCodeInput = {
  eq?: PopupResultTypeCode | null,
  ne?: PopupResultTypeCode | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Popup = {
  __typename: "Popup",
  clientID: string,
  createdAt: string,
  type: PopupTypeCode,
  resultType: PopupResultTypeCode,
  answeredCorrectly: boolean,
  mouseXY?: string | null,
  buttonClicked?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  device?: Device | null,
  deviceName: string,
  user?: User | null,
  userName: string,
  updatedAt: string,
};

export type UpdatePopupInput = {
  clientID: string,
  createdAt: string,
  type?: PopupTypeCode | null,
  resultType?: PopupResultTypeCode | null,
  answeredCorrectly?: boolean | null,
  mouseXY?: string | null,
  buttonClicked?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  deviceName?: string | null,
  userName?: string | null,
};

export type DeletePopupInput = {
  clientID: string,
  createdAt: string,
};

export type CreateReportInput = {
  clientID: string,
  type: ReportType,
  from: string,
  to: string,
  createdAt?: string | null,
  id?: string | null,
};

export enum ReportType {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}


export type ModelReportConditionInput = {
  clientID?: ModelIDInput | null,
  type?: ModelReportTypeInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type ModelReportTypeInput = {
  eq?: ReportType | null,
  ne?: ReportType | null,
};

export type Report = {
  __typename: "Report",
  clientID: string,
  type: ReportType,
  from: string,
  to: string,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type UpdateReportInput = {
  clientID?: string | null,
  type?: ReportType | null,
  from?: string | null,
  to?: string | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteReportInput = {
  id: string,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  legalName?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
};

export type SearchableClientFilterInput = {
  id?: SearchableIDFilterInput | null,
  legalName?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableClientFilterInput | null > | null,
  or?: Array< SearchableClientFilterInput | null > | null,
  not?: SearchableClientFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableClientSortInput = {
  field?: SearchableClientSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableClientSortableFields {
  id = "id",
  legalName = "legalName",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableClientAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableClientAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableClientAggregateField {
  id = "id",
  legalName = "legalName",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableClientConnection = {
  __typename: "SearchableClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelDeviceFilterInput = {
  clientID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  lastEventId?: ModelIDInput | null,
  lastEventCreatedAt?: ModelStringInput | null,
  and?: Array< ModelDeviceFilterInput | null > | null,
  or?: Array< ModelDeviceFilterInput | null > | null,
  not?: ModelDeviceFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchableDeviceFilterInput = {
  clientID?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  location?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  lastEventId?: SearchableIDFilterInput | null,
  lastEventCreatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableDeviceFilterInput | null > | null,
  or?: Array< SearchableDeviceFilterInput | null > | null,
  not?: SearchableDeviceFilterInput | null,
};

export type SearchableDeviceSortInput = {
  field?: SearchableDeviceSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDeviceSortableFields {
  clientID = "clientID",
  name = "name",
  location = "location",
  description = "description",
  lastEventId = "lastEventId",
  lastEventCreatedAt = "lastEventCreatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDeviceAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDeviceAggregateField,
};

export enum SearchableDeviceAggregateField {
  clientID = "clientID",
  name = "name",
  location = "location",
  description = "description",
  lastEventId = "lastEventId",
  lastEventCreatedAt = "lastEventCreatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDeviceConnection = {
  __typename: "SearchableDeviceConnection",
  items:  Array<Device | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelUserFilterInput = {
  clientID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  lastEventId?: ModelIDInput | null,
  lastEventCreatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type SearchableUserFilterInput = {
  clientID?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  lastEventId?: SearchableIDFilterInput | null,
  lastEventCreatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  clientID = "clientID",
  name = "name",
  description = "description",
  lastEventId = "lastEventId",
  lastEventCreatedAt = "lastEventCreatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserAggregateField,
};

export enum SearchableUserAggregateField {
  clientID = "clientID",
  name = "name",
  description = "description",
  lastEventId = "lastEventId",
  lastEventCreatedAt = "lastEventCreatedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelEventFilterInput = {
  clientID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  type?: ModelEventTypeCodeInput | null,
  deviceName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type SearchableEventFilterInput = {
  clientID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  deviceName?: SearchableStringFilterInput | null,
  userName?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableEventFilterInput | null > | null,
  or?: Array< SearchableEventFilterInput | null > | null,
  not?: SearchableEventFilterInput | null,
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSortableFields {
  clientID = "clientID",
  createdAt = "createdAt",
  deviceName = "deviceName",
  userName = "userName",
  updatedAt = "updatedAt",
}


export type SearchableEventAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableEventAggregateField,
};

export enum SearchableEventAggregateField {
  clientID = "clientID",
  createdAt = "createdAt",
  type = "type",
  deviceName = "deviceName",
  userName = "userName",
  updatedAt = "updatedAt",
}


export type SearchableEventConnection = {
  __typename: "SearchableEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPopupFilterInput = {
  clientID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  type?: ModelPopupTypeCodeInput | null,
  resultType?: ModelPopupResultTypeCodeInput | null,
  answeredCorrectly?: ModelBooleanInput | null,
  mouseXY?: ModelStringInput | null,
  buttonClicked?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  deviceName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  and?: Array< ModelPopupFilterInput | null > | null,
  or?: Array< ModelPopupFilterInput | null > | null,
  not?: ModelPopupFilterInput | null,
};

export type ModelPopupConnection = {
  __typename: "ModelPopupConnection",
  items:  Array<Popup | null >,
  nextToken?: string | null,
};

export type SearchablePopupFilterInput = {
  clientID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  answeredCorrectly?: SearchableBooleanFilterInput | null,
  mouseXY?: SearchableStringFilterInput | null,
  buttonClicked?: SearchableStringFilterInput | null,
  startDateTime?: SearchableStringFilterInput | null,
  endDateTime?: SearchableStringFilterInput | null,
  deviceName?: SearchableStringFilterInput | null,
  userName?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  resultType?: SearchableStringFilterInput | null,
  and?: Array< SearchablePopupFilterInput | null > | null,
  or?: Array< SearchablePopupFilterInput | null > | null,
  not?: SearchablePopupFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchablePopupSortInput = {
  field?: SearchablePopupSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePopupSortableFields {
  clientID = "clientID",
  createdAt = "createdAt",
  answeredCorrectly = "answeredCorrectly",
  mouseXY = "mouseXY",
  buttonClicked = "buttonClicked",
  startDateTime = "startDateTime",
  endDateTime = "endDateTime",
  deviceName = "deviceName",
  userName = "userName",
  updatedAt = "updatedAt",
}


export type SearchablePopupAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePopupAggregateField,
};

export enum SearchablePopupAggregateField {
  clientID = "clientID",
  createdAt = "createdAt",
  type = "type",
  resultType = "resultType",
  answeredCorrectly = "answeredCorrectly",
  mouseXY = "mouseXY",
  buttonClicked = "buttonClicked",
  startDateTime = "startDateTime",
  endDateTime = "endDateTime",
  deviceName = "deviceName",
  userName = "userName",
  updatedAt = "updatedAt",
}


export type SearchablePopupConnection = {
  __typename: "SearchablePopupConnection",
  items:  Array<Popup | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelReportFilterInput = {
  clientID?: ModelIDInput | null,
  type?: ModelReportTypeInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type SearchableReportFilterInput = {
  clientID?: SearchableIDFilterInput | null,
  from?: SearchableStringFilterInput | null,
  to?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  id?: SearchableIDFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableReportFilterInput | null > | null,
  or?: Array< SearchableReportFilterInput | null > | null,
  not?: SearchableReportFilterInput | null,
};

export type SearchableReportSortInput = {
  field?: SearchableReportSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReportSortableFields {
  clientID = "clientID",
  from = "from",
  to = "to",
  createdAt = "createdAt",
  id = "id",
  updatedAt = "updatedAt",
}


export type SearchableReportAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableReportAggregateField,
};

export enum SearchableReportAggregateField {
  clientID = "clientID",
  type = "type",
  from = "from",
  to = "to",
  createdAt = "createdAt",
  id = "id",
  updatedAt = "updatedAt",
}


export type SearchableReportConnection = {
  __typename: "SearchableReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  legalName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionDeviceFilterInput = {
  clientID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  lastEventId?: ModelSubscriptionIDInput | null,
  lastEventCreatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeviceFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  clientID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  lastEventId?: ModelSubscriptionIDInput | null,
  lastEventCreatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionEventFilterInput = {
  clientID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  deviceName?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
};

export type ModelSubscriptionPopupFilterInput = {
  clientID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  resultType?: ModelSubscriptionStringInput | null,
  answeredCorrectly?: ModelSubscriptionBooleanInput | null,
  mouseXY?: ModelSubscriptionStringInput | null,
  buttonClicked?: ModelSubscriptionStringInput | null,
  startDateTime?: ModelSubscriptionStringInput | null,
  endDateTime?: ModelSubscriptionStringInput | null,
  deviceName?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPopupFilterInput | null > | null,
  or?: Array< ModelSubscriptionPopupFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionReportFilterInput = {
  clientID?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  from?: ModelSubscriptionStringInput | null,
  to?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportFilterInput | null > | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input: UpdateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type CreatePopupMutationVariables = {
  input: CreatePopupInput,
  condition?: ModelPopupConditionInput | null,
};

export type CreatePopupMutation = {
  createPopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type UpdatePopupMutationVariables = {
  input: UpdatePopupInput,
  condition?: ModelPopupConditionInput | null,
};

export type UpdatePopupMutation = {
  updatePopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type DeletePopupMutationVariables = {
  input: DeletePopupInput,
  condition?: ModelPopupConditionInput | null,
};

export type DeletePopupMutation = {
  deletePopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      legalName: string,
      devices?:  {
        __typename: "ModelDeviceConnection",
        items:  Array< {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchClientsQueryVariables = {
  filter?: SearchableClientFilterInput | null,
  sort?: Array< SearchableClientSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableClientAggregationInput | null > | null,
};

export type SearchClientsQuery = {
  searchClients?:  {
    __typename: "SearchableClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      legalName: string,
      devices?:  {
        __typename: "ModelDeviceConnection",
        items:  Array< {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetDeviceQueryVariables = {
  clientID: string,
  name: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDevicesQueryVariables = {
  clientID?: string | null,
  name?: ModelStringKeyConditionInput | null,
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDevicesQueryVariables = {
  filter?: SearchableDeviceFilterInput | null,
  sort?: Array< SearchableDeviceSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDeviceAggregationInput | null > | null,
};

export type SearchDevicesQuery = {
  searchDevices?:  {
    __typename: "SearchableDeviceConnection",
    items:  Array< {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetUserQueryVariables = {
  clientID: string,
  name: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  clientID?: string | null,
  name?: ModelStringKeyConditionInput | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: Array< SearchableUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserAggregationInput | null > | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEventQueryVariables = {
  clientID: string,
  createdAt: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  clientID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEventsQueryVariables = {
  filter?: SearchableEventFilterInput | null,
  sort?: Array< SearchableEventSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableEventAggregationInput | null > | null,
};

export type SearchEventsQuery = {
  searchEvents?:  {
    __typename: "SearchableEventConnection",
    items:  Array< {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPopupQueryVariables = {
  clientID: string,
  createdAt: string,
};

export type GetPopupQuery = {
  getPopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type ListPopupsQueryVariables = {
  clientID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelPopupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPopupsQuery = {
  listPopups?:  {
    __typename: "ModelPopupConnection",
    items:  Array< {
      __typename: "Popup",
      clientID: string,
      createdAt: string,
      type: PopupTypeCode,
      resultType: PopupResultTypeCode,
      answeredCorrectly: boolean,
      mouseXY?: string | null,
      buttonClicked?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPopupsQueryVariables = {
  filter?: SearchablePopupFilterInput | null,
  sort?: Array< SearchablePopupSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePopupAggregationInput | null > | null,
};

export type SearchPopupsQuery = {
  searchPopups?:  {
    __typename: "SearchablePopupConnection",
    items:  Array< {
      __typename: "Popup",
      clientID: string,
      createdAt: string,
      type: PopupTypeCode,
      resultType: PopupResultTypeCode,
      answeredCorrectly: boolean,
      mouseXY?: string | null,
      buttonClicked?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      clientID: string,
      type: ReportType,
      from: string,
      to: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportsByClientIdAndCreatedAtQueryVariables = {
  clientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportsByClientIdAndCreatedAtQuery = {
  reportsByClientIdAndCreatedAt?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      clientID: string,
      type: ReportType,
      from: string,
      to: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReportsQueryVariables = {
  filter?: SearchableReportFilterInput | null,
  sort?: Array< SearchableReportSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableReportAggregationInput | null > | null,
};

export type SearchReportsQuery = {
  searchReports?:  {
    __typename: "SearchableReportConnection",
    items:  Array< {
      __typename: "Report",
      clientID: string,
      type: ReportType,
      from: string,
      to: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    legalName: string,
    devices?:  {
      __typename: "ModelDeviceConnection",
      items:  Array< {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceFilterInput | null,
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?:  {
    __typename: "Device",
    clientID: string,
    name: string,
    location?: string | null,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    clientID: string,
    name: string,
    description?: string | null,
    lastEvent?:  {
      __typename: "Event",
      clientID: string,
      createdAt: string,
      type: EventTypeCode,
      device?:  {
        __typename: "Device",
        clientID: string,
        name: string,
        location?: string | null,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      deviceName: string,
      user?:  {
        __typename: "User",
        clientID: string,
        name: string,
        description?: string | null,
        lastEvent?:  {
          __typename: "Event",
          clientID: string,
          createdAt: string,
          type: EventTypeCode,
          deviceName: string,
          userName: string,
          updatedAt: string,
        } | null,
        lastEventId?: string | null,
        lastEventCreatedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userName: string,
      updatedAt: string,
    } | null,
    lastEventId?: string | null,
    lastEventCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    clientID: string,
    createdAt: string,
    type: EventTypeCode,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePopupSubscriptionVariables = {
  filter?: ModelSubscriptionPopupFilterInput | null,
};

export type OnCreatePopupSubscription = {
  onCreatePopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePopupSubscriptionVariables = {
  filter?: ModelSubscriptionPopupFilterInput | null,
};

export type OnUpdatePopupSubscription = {
  onUpdatePopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePopupSubscriptionVariables = {
  filter?: ModelSubscriptionPopupFilterInput | null,
};

export type OnDeletePopupSubscription = {
  onDeletePopup?:  {
    __typename: "Popup",
    clientID: string,
    createdAt: string,
    type: PopupTypeCode,
    resultType: PopupResultTypeCode,
    answeredCorrectly: boolean,
    mouseXY?: string | null,
    buttonClicked?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    device?:  {
      __typename: "Device",
      clientID: string,
      name: string,
      location?: string | null,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceName: string,
    user?:  {
      __typename: "User",
      clientID: string,
      name: string,
      description?: string | null,
      lastEvent?:  {
        __typename: "Event",
        clientID: string,
        createdAt: string,
        type: EventTypeCode,
        device?:  {
          __typename: "Device",
          clientID: string,
          name: string,
          location?: string | null,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        deviceName: string,
        user?:  {
          __typename: "User",
          clientID: string,
          name: string,
          description?: string | null,
          lastEventId?: string | null,
          lastEventCreatedAt?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        userName: string,
        updatedAt: string,
      } | null,
      lastEventId?: string | null,
      lastEventCreatedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    clientID: string,
    type: ReportType,
    from: string,
    to: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};
