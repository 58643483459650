import { Button } from "@aws-amplify/ui-react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form";
import { useData } from "../../contexts/DataProvider";
import { IFormData } from "./IFormData";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  control: Control<IFormData, any>;
  handleSubmit: UseFormHandleSubmit<IFormData>;
  onSubmit: SubmitHandler<IFormData>;
}
const Filter = ({ control, handleSubmit, onSubmit }: IProps) => {
  const { devices, users } = useData();
  const intl = useIntl();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Controller
            name="users"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                size="small"
                options={users}
                value={value}
                defaultValue={value}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                disableCloseOnSelect
                onChange={(e, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label={intl.formatMessage({id: "userSingle", defaultMessage: "Utilisateur"})} 
                    placeholder={intl.formatMessage({id: "userSingle", defaultMessage: "Utilisateur"})} 
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name="devices"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                size="small"
                options={devices}
                value={value}
                defaultValue={value}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                disableCloseOnSelect
                onChange={(e, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({id: "workspaceSingle", defaultMessage: "Postes de travail"})}
                    placeholder={intl.formatMessage({id: "workspaceSingle", defaultMessage: "Postes de travail"})}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name="dates"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateRangePicker
                calendars={2}
                onChange={onChange}
                value={value}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  textField: {
                    size: "small",
                    sx: {
                      width: 200,
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button type="submit" marginBottom={10} marginTop={10} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {
            intl.formatMessage({
              id: "submitButton",
              defaultMessage: "Envoyer",
            })
          }
        </Button>
      </Grid>
    </form>
  );
};

export default Filter;

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Cette semaine",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Semaine derniere",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Ce mois",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Reinitialiser",
    getValue: () => [null, null],
  },
];
