/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ruthereEventsApi",
            "endpoint": "https://nbo7z0wve3.execute-api.ca-central-1.amazonaws.com/dev",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://frp63my6cbdydfq66shxswlb2m.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fh3upul6tvh45jxxyeczfd7rwq",
    "aws_cognito_identity_pool_id": "ca-central-1:5b481097-bb60-465c-b400-900514e6e2d1",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_VhHkJbVI1",
    "aws_user_pools_web_client_id": "6tbp3pmhilids36mkhmpear95r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ruthereconsolegood01038b46d6544865bbf24f5b9e73f173522-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
